import { TypeInlichting } from '../bff-client/bff-client'

export const mapInlichtingTemplates = (type: TypeInlichting) => {
  switch (type) {
    case TypeInlichting.AankoopOfGebruikGemeentegrond:
      return {
        template: 'default',
        previewTemplate: 'aankoop-of-gebruik-gemeentegrond',
        attributesTemplate: 'aankoop-of-gebruik-gemeentegrond'
      }
    case TypeInlichting.AsBuiltAttest:
      return {
        template: 'default',
        previewTemplate: 'as-built-attest',
        attributesTemplate: 'as-built-attest'
      }
    case TypeInlichting.Bedrijventerrein:
      return {
        template: 'read-only-default',
        previewTemplate: 'bedrijventerrein'
      }
    case TypeInlichting.BeschermdDuingebied:
      return {
        template: 'read-only-map',
        previewTemplate: 'beschermd-duin-gebied'
      }
    case TypeInlichting.BeschermdWaterwinningsgebied:
      return {
        template: 'read-only-map',
        previewTemplate: 'beschermd-waterwinningsgebied'
      }
    case TypeInlichting.BesluitBurgemeesterInzakeOpenbareVeiligheid:
      return {
        template: 'default',
        previewTemplate: 'besluit-burgemeester-openbare-veiligheid',
        attributesTemplate: 'besluit-burgemeester-openbare-veiligheid'
      }
    case TypeInlichting.StedenbouwkundigeOvertreding:
      return {
        template: 'default',
        previewTemplate: 'stedenbouwkundige-overtreding',
        attributesTemplate: 'stedenbouwkundige-overtreding'
      }
    case TypeInlichting.Brownfield:
      return {
        template: 'read-only-default',
        previewTemplate: 'brownfield'
      }
    case TypeInlichting.ComplexProject:
      return {
        template: 'default',
        previewTemplate: 'complex-project',
        attributesTemplate: 'complex-project'
      }
    case TypeInlichting.Conformiteitsattest:
      return {
        template: 'read-only-default',
        previewTemplate: 'conformiteitsattest'
      }
    case TypeInlichting.WettelijkeErfdienstbaarheidVanOpenbaarNut:
      return {
        template: 'default',
        previewTemplate: 'erfdienstbaarheden-openbaar-nut',
        attributesTemplate: 'ed-openbaarnut'
      }
    case TypeInlichting.GemeentelijkeHeffing:
      return {
        template: 'default',
        previewTemplate: 'gemeentelijke-heffing',
        attributesTemplate: 'gemeentelijke-heffing'
      }
    case TypeInlichting.Grondverschuivingsgevoeligheid:
      return {
        template: 'read-only-default',
        previewTemplate: 'grondverschuivingsgevoeligheid'
      }
    case TypeInlichting.Habitatrichtlijngebied:
      return {
        template: 'read-only-map',
        previewTemplate: 'habitat-richtlijn-gebied'
      }
    case TypeInlichting.Herstelvordering:
      return {
        template: 'read-only-default',
        previewTemplate: 'herstelvordering'
      }
    case TypeInlichting.LeegstaandeEnVerwaarloosdeGebouwen:
      return {
        template: 'default',
        previewTemplate: 'leegstaande-gebouwen',
        attributesTemplate: 'leegstaande-gebouwen'
      }
    case TypeInlichting.LeegstaandeEnVerwaarloosdeBedrijfsruimten:
      return {
        template: 'default',
        previewTemplate: 'leegstaande-bedrijfsruimten',
        attributesTemplate: 'leegstaande-bedrijfsruimten'
      }
    case TypeInlichting.Kapmachtiging:
      return {
        template: 'default',
        previewTemplate: 'kapmachtiging',
        attributesTemplate: 'kapmachtiging'
      }
    case TypeInlichting.LokaalOnroerendErfgoed:
      return {
        template: 'default',
        previewTemplate: 'lokaal-onroerend-erfgoed',
        attributesTemplate: 'lokaal-onroerend-erfgoed'
      }
    case TypeInlichting.Milieuvergunning:
      return {
        template: 'default',
        previewTemplate: 'milieuvergunning',
        attributesTemplate: 'milieuvergunning'
      }
    case TypeInlichting.Natuurbeheerplan:
      return {
        template: 'read-only-map',
        previewTemplate: 'beheerplan'
      }
    case TypeInlichting.Omgevingsvergunning:
      return {
        template: 'default',
        previewTemplate: 'omgevingsvergunning',
        attributesTemplate: 'omgevingsvergunning'
      }
    case TypeInlichting.OnbebouwdePercelen:
      return {
        template: 'default',
        previewTemplate: 'onbebouwde-percelen',
        attributesTemplate: 'onbebouwde-percelen'
      }
    case TypeInlichting.OnbewoonbareEnOngeschikteWoning:
      return {
        template: 'read-only-default',
        previewTemplate: 'onbewoonbare-ongeschikte-woningen'
      }
    case TypeInlichting.OverstromingsgebiedEnOeverzone:
      return {
        template: 'read-only-map',
        previewTemplate: 'overstromingsgebied-oeverzone'
      }
    case TypeInlichting.Overstromingsgevoeligheid:
      return {
        template: 'read-only-map',
        previewTemplate: 'overstromingsgevoeligheid'
      }
    case TypeInlichting.OverstromingsgevoeligheidV2:
      return {
        template: 'read-only-map',
        previewTemplate: 'overstromingsgevoeligheid-v2'
      }
    case TypeInlichting.OnroerendErfgoed:
      return {
        template: 'read-only-default',
        previewTemplate: 'onroerend-erfgoed'
      }
    case TypeInlichting.PlanbatenOfSchade:
      return {
        template: 'default',
        previewTemplate: 'planbaten-of-schade',
        attributesTemplate: 'planbaten-of-schade'
      }
    case TypeInlichting.PlanologischAttest:
      return {
        template: 'default',
        previewTemplate: 'planologisch-attest',
        attributesTemplate: 'planologisch-attest'
      }
    case TypeInlichting.RechtVanVoorkoop:
      return {
        template: 'read-only-default',
        previewTemplate: 'recht-van-voorkoop'
      }
    case TypeInlichting.Risicogrond:
      return {
        template: 'default',
        previewTemplate: 'risicogrond',
        attributesTemplate: 'risicogrond'
      }
    case TypeInlichting.RisicogrondV2:
      return {
        template: 'default',
        previewTemplate: 'risicogrond-v2',
        attributesTemplate: 'risicogrond-v2'
      }
    case TypeInlichting.RisicozoneVoorOverstromingen:
      return {
        template: 'read-only-map',
        previewTemplate: 'risicozone-voor-overstromingen'
      }
    case TypeInlichting.GemeentelijkRooilijnplan:
      return {
        template: 'default',
        previewTemplate: 'gemeentelijk-rooilijnplan',
        attributesTemplate: 'gemeentelijk-rooilijnplan'
      }
    case TypeInlichting.GemeentelijkOnteigeningsplan:
      return {
        template: 'default',
        previewTemplate: 'gemeentelijk-onteigeningsplan',
        attributesTemplate: 'gemeentelijk-onteigeningsplan'
      }
    case TypeInlichting.Signaalgebied:
      return {
        template: 'read-only-map',
        previewTemplate: 'signaalgebied'
      }
    case TypeInlichting.SoortWegWaarlangsPerceelGelegenIs:
      return {
        template: 'default',
        previewTemplate: 'soort-weg'
      }
    case TypeInlichting.SoortWegWaarlangsPerceelGelegenIsV2:
      return {
        template: 'default',
        previewTemplate: 'soort-weg-v2',
        attributesTemplate: 'soort-weg-v2'
      }
    case TypeInlichting.Splitsing:
      return {
        template: 'default',
        previewTemplate: 'splitsing',
        attributesTemplate: 'splitsing'
      }
    case TypeInlichting.StedenbouwkundigAttest:
      return {
        template: 'default',
        previewTemplate: 'stedenbouwkundig-attest',
        attributesTemplate: 'stedenbouwkundig-attest'
      }
    case TypeInlichting.StedenbouwkundigeMelding:
      return {
        template: 'default',
        previewTemplate: 'stedenbouwkundige-melding',
        attributesTemplate: 'stedenbouwkundige-melding'
      }
    case TypeInlichting.StedenbouwkundigeVergunning:
      return {
        template: 'default',
        previewTemplate: 'stedenbouwkundige-vergunning',
        attributesTemplate: 'stedenbouwkundige-vergunning'
      }
    case TypeInlichting.VenEnIvonGebied:
      return {
        template: 'read-only-map',
        previewTemplate: 'venenivongebied'
      }
    case TypeInlichting.VerbodTotPermanentWonen:
      return {
        template: 'default',
        previewTemplate: 'verbod-tot-permanent-wonen',
        attributesTemplate: 'verbod-permanent-wonen'
      }
    case TypeInlichting.Verkavelingsvergunning:
      return {
        template: 'default',
        previewTemplate: 'verkavelingsvergunning',
        attributesTemplate: 'verkavelingsvergunning'
      }
    case TypeInlichting.VergundGeachteGebouwen:
      return {
        template: 'default',
        previewTemplate: 'vergund-geachte-gebouwen',
        attributesTemplate: 'vergund-geachte-gebouwen'
      }
    case TypeInlichting.Vergunningstoestand:
      return {
        template: 'default',
        previewTemplate: 'vergunningstoestand',
        attributesTemplate: 'vergunningstoestand'
      }
    case TypeInlichting.Beleidsplan:
      return {
        template: 'default',
        previewTemplate: 'beleidsplan',
        attributesTemplate: 'beleidsplan'
      }
    case TypeInlichting.Vogelrichtlijngebied:
      return {
        template: 'read-only-map',
        previewTemplate: 'vogelrichtlijngebied'
      }
    case TypeInlichting.Natuurinrichtingsproject:
      return {
        template: 'read-only-map',
        previewTemplate: 'natuurinrichtingsproject'
      }
    case TypeInlichting.VoorwaardenVanuitEenGemeentelijkeAkte:
      return {
        template: 'default',
        previewTemplate: 'voorwaarden-gemeentelijke-akte',
        attributesTemplate: 'voorwaarden-gemeentelijke-akte'
      }
    case TypeInlichting.Zoneringsplan:
      return {
        template: 'read-only-map',
        previewTemplate: 'zoneringsplan'
      }
    default:
      return {
        template: 'default',
        previewTemplate: 'default',
        attributesTemplate: 'default'
      }
  }
}

export function groupBy<T, K extends keyof T> (list: T[], key: K): Map<T[K], T[]> {
  return list?.reduce((map, item) => {
    const keyValue = item[key]
    const group = map.get(keyValue) || []
    group.push(item)
    map.set(keyValue, group)
    return map
  }, new Map<T[K], T[]>())
}
